/**
 * Catalan translation for bootstrap-year-calendar
 * Can Antaviana
 * Based on Catalan translation for bootstrap-datepicker
 * J. Garcia <jogaco.en@gmail.com>
 */
 
;(function($){
	$.fn.calendar.dates['ca'] = {
		days:["Diumenge","Dilluns","Dimarts","Dimecres","Dijous","Divendres","Dissabte"],
		daysShort:["Diu","Dil","Dmt","Dmc","Dij","Div","Dis"],
		daysMin:["dg","dl","dt","dc","dj","dv","ds"],
		months:["Gener","Febrer","Març","Abril","Maig","Juny","Juliol","Agost","Setembre","Octubre","Novembre","Desembre"],
		monthsShort:["Gen","Feb","Mar","Abr","Mai","Jun","Jul","Ago","Set","Oct","Nov","Des"],
		weekShort: 'S',
		weekStart:1
	}
}(jQuery));
