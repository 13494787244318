
$(document).on('click', "a.confirmDelete", function(event) {
    event.preventDefault();
    var url=$(this).attr('href');
    vex.dialog.confirm({
        message: 'Estàs segur que vols esborrar aquesta entrada?',
        buttons: [
            $.extend({}, vex.dialog.buttons.YES, {
                text: 'Esborra'
            }), $.extend({}, vex.dialog.buttons.NO, {
                text: 'Cancel·la'
            })
        ],
        callback: function(value) {
            if (value) {
                window.location = url
            }
        }
    });
});

$(document).on('click', "a.sendVCard", function(event) {
    event.preventDefault();
    var url=$(this).attr('href');
    vex.dialog.open({
        message: 'Enviament de la targeta de visita',
        input: [
        	'<div class="vex-container">',
        	'<div class="vex-custom-field-wrapper">',
        		'<label for="date">Correu electrònic</label>',
        		'<div class="vex-custom-input-wrapper">',
                    '<input name="email" type="email" value="" />',
                '</div>',
            '</div>',
            '<div class="vex-custom-field-wrapper">',
	    		'<label for="date">Missatge</label>',
	    		'<div class="vex-custom-input-wrapper">',
	                '<textarea name="body" placeholder="Escriu el missatge que rebrà la persona a la qual envies la targeta de visita"/></textarea>',
	            '</div>',
	        '</div>',
	        '</div>',
        ].join(''),
        buttons: [
            $.extend({}, vex.dialog.buttons.YES, {
                text: 'Envia'
            }), $.extend({}, vex.dialog.buttons.NO, {
                text: 'Cancel·la'
            })
        ],
        onSubmit: function(e) {
        	e.preventDefault();

        	var ajaxUrl = url;
            var ajaxData = $(e.target).serialize();
            $.ajax({
                url: ajaxUrl,
                data: ajaxData,
                type: 'get',
                dataType: 'json',
                success: function(response){
                    if(response.type == 'error'){
                        $(".vex-container").prepend('<div class="alert alert-danger" role="alert">' + response.message + '</div>');
                    } else if (response.type == 'success'){
                    	$(".vex-container").html('<div class="alert alert-success" role="alert">' + response.message + '</div>');
                    	$(".vex-dialog-buttons").hide();


                    	setTimeout(function(){vex.closeAll();}, 3000);
                    }
                },
                error: function(response) {
                	console.log(response);
                }
            });
        	return false;
        }
    });
});


$(document).on('click', "a.changeType", function(event) {
    event.preventDefault();
    var url=$(this).attr('href');
    var options = '';
    var selected = '';
    var types = $(this).data('types');
    for (var prop in types) {
    	selected = '';
    	if (prop == $(this).data('type')) {
    		selected = 'selected="selected"';
    	}
    	options = options + '<option value="'+ prop +'" ' + selected + '>' + types[prop] + '</option>';
    }

    vex.dialog.open({
        message: 'Modifica del tipus d\'entrada',
        input: [
        	'<div class="vex-container">',
        	'<div class="vex-custom-field-wrapper">',
        		'<div class="vex-custom-input-wrapper">',
                    '<select name="tipus" class="form-control">',
                     options,
                    '</select>',
                '</div>',
            '</div>',
	        '</div>',
        ].join(''),
        buttons: [
            $.extend({}, vex.dialog.buttons.YES, {
                text: 'Actualitza'
            }), $.extend({}, vex.dialog.buttons.NO, {
                text: 'Cancel·la'
            })
        ],
        callback: function(value) {
            if (value) {
                window.location = url + '&type=' + $('.vex-custom-input-wrapper select[name="tipus"]').val();
            }
        }
    });
});


$(document).ready(function() {
//	vex.defaultOptions.className = "vex-theme-default";
//	vex.defaultOptions.className = "vex-theme-flat-attack";
	vex.defaultOptions.className = "vex-theme-plain";
	$('body').bootstrapMaterialDesign();


	/*
	 * Calendar mensual
	 */
	if ($('#month-calendar').length) {
	    $('#month-calendar').fullCalendar({
	      header: {
	        left: '',//'prev,next today',
	        center: 'title',
	        right: '' //'month,basicWeek,basicDay'
	      },
	      themeSystem: 'bootstrap4',
	      defaultDate: calendarDefaultDate,
	      navLinks: false, // can click day/week names to navigate views
//	      editable: true,
	      eventLimit: true, // allow "more" link when too many events
	      events: calendarEvents
//	      events: function(start, end, timezone, callback) {
//    	    $.ajax({
//    	      url: '/calendari/ajax-events-load.php',
//    	      dataType: 'json',
//    	      data: {
//    	        // our hypothetical feed requires UNIX timestamps
//    	        start: start.unix(),
//    	        end: end.unix()
//    	      },
//    	      success: function(doc) {
//    	        var events = doc;
//    	        callback(events);
//    	      }
//    	    });
//    	  },
//	      events: [
//	        {
//	          title: 'All Day Event',
//	          start: '2018-03-01'
//	        },
//	        {
//	          title: 'Long Event',
//	          start: '2018-03-07',
//	          end: '2018-03-10'
//	        },
//	        {
//	          id: 999,
//	          title: 'Repeating Event',
//	          start: '2018-03-09T16:00:00'
//	        },
//	        {
//	          id: 999,
//	          title: 'Repeating Event',
//	          start: '2018-03-16T16:00:00'
//	        },
//	        {
//	          title: 'Conference',
//	          start: '2018-03-11',
//	          end: '2018-03-13'
//	        },
//	        {
//	          title: 'Meeting',
//	          start: '2018-03-12T10:30:00',
//	          end: '2018-03-12T12:30:00'
//	        },
//	        {
//	          title: 'Lunch',
//	          start: '2018-03-12T12:00:00'
//	        },
//	        {
//	          title: 'Meeting',
//	          start: '2018-03-12T14:30:00'
//	        },
//	        {
//	          title: 'Happy Hour',
//	          start: '2018-03-12T17:30:00'
//	        },
//	        {
//	          title: 'Dinner',
//	          start: '2018-03-12T20:00:00'
//	        },
//	        {
//	          title: 'Birthday Party',
//	          start: '2018-03-13T07:00:00'
//	        },
//	        {
//	          title: 'Click for Google',
//	          url: 'http://google.com/',
//	          start: '2018-03-28'
//	        }
//	      ]
	    });
	}

	/*
	 * Calendar anual
	 */
	if ($('#year-calendar').length) {
	    $('#year-calendar').calendar({
	    	maxDate: anualCalendarMaxDate,
	    	minDate: anualCalendarMinDate,
	    	startYear: anualCalendarStartYear,
	        enableContextMenu: true,
	        language: 'ca',
	        style: 'background',
//	        enableRangeSelection: true,
//	        contextMenuItems:[
//	            {
//	                text: 'Update',
//	                click: editEvent
//	            },
//	            {
//	                text: 'Delete',
//	                click: deleteEvent
//	            }
//	        ],
//	        selectRange: function(e) {
//	            editEvent({ startDate: e.startDate, endDate: e.endDate });
//	        },
	        mouseOnDay: function(e) {
	            if(e.events.length > 0) {
	                var content = '';

	                for(var i in e.events) {
	                    content += '<div class="event-tooltip-content">'
	                                    + '<div class="event-name" style="color:' + e.events[i].color + '">' + e.events[i].name + '</div>'
	                                    + '<div class="event-location">' + e.events[i].location + '</div>'
	                                + '</div>';
	                }

	                $(e.element).popover({
	                    trigger: 'manual',
	                    container: 'body',
	                    html:true,
	                    content: content
	                });

	                $(e.element).popover('show');
	            }
	        },
	        mouseOutDay: function(e) {
	            if(e.events.length > 0) {
	                $(e.element).popover('hide');
	            }
	        },
	        dayContextMenu: function(e) {
	            $(e.element).popover('hide');
	        },
	        dataSource: anualCalendarEvents
//	        dataSource: [
//	            {
//	                id: 0,
//	                name: 'Google I/O',
//	                location: 'San Francisco, CA',
//	                startDate: new Date(currentYear, 4, 28),
//	                endDate: new Date(currentYear, 4, 29)
//	            },
//	            {
//	                id: 1,
//	                name: 'Microsoft Convergence',
//	                location: 'New Orleans, LA',
//	                startDate: new Date(currentYear, 2, 16),
//	                endDate: new Date(currentYear, 2, 19)
//	            },
//	            {
//	                id: 2,
//	                name: 'Microsoft Build Developer Conference',
//	                location: 'San Francisco, CA',
//	                startDate: new Date(currentYear, 3, 29),
//	                endDate: new Date(currentYear, 4, 1)
//	            },
//	            {
//	                id: 3,
//	                name: 'Apple Special Event',
//	                location: 'San Francisco, CA',
//	                startDate: new Date(currentYear, 8, 1),
//	                endDate: new Date(currentYear, 8, 1)
//	            },
//	            {
//	                id: 4,
//	                name: 'Apple Keynote',
//	                location: 'San Francisco, CA',
//	                startDate: new Date(currentYear, 8, 9),
//	                endDate: new Date(currentYear, 8, 9)
//	            },
//	            {
//	                id: 5,
//	                name: 'Chrome Developer Summit',
//	                location: 'Mountain View, CA',
//	                startDate: new Date(currentYear, 10, 17),
//	                endDate: new Date(currentYear, 10, 18)
//	            },
//	            {
//	                id: 6,
//	                name: 'F8 2015',
//	                location: 'San Francisco, CA',
//	                startDate: new Date(currentYear, 2, 25),
//	                endDate: new Date(currentYear, 2, 26)
//	            },
//	            {
//	                id: 7,
//	                name: 'Yahoo Mobile Developer Conference',
//	                location: 'New York',
//	                startDate: new Date(currentYear, 7, 25),
//	                endDate: new Date(currentYear, 7, 26)
//	            },
//	            {
//	                id: 8,
//	                name: 'Android Developer Conference',
//	                location: 'Santa Clara, CA',
//	                startDate: new Date(currentYear, 11, 1),
//	                endDate: new Date(currentYear, 11, 4)
//	            },
//	            {
//	                id: 9,
//	                name: 'LA Tech Summit',
//	                location: 'Los Angeles, CA',
//	                startDate: new Date(currentYear, 10, 17),
//	                endDate: new Date(currentYear, 10, 17)
//	            }
//	        ]
	    });
	}
});



$('table.dataTable').each(function( index ) {
    var paginate = true;
    if ($(this).find('tr').length <= 10) {
        paginate = false;
    }
    var scroll = false;

    if ($(this).data('scroll')) {
        scroll = true;
        //$(this).find('th, tr').css('white-space', 'nowrap');
    }
    $(this).DataTable({
        bPaginate: paginate,
        bInfo: paginate,
        bSortClasses: false,
        scrollX: scroll,
        autoWidth: false,
        dom:
            "<'row'<'col-sm-8'i><'col-sm-4'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-5'l><'col-sm-7'p>>",
        stateSave: true,
        stateDuration: 60 * 60,
        language: {
            "lengthMenu": "Mostrant _MENU_ entrades per pàgina",
            "zeroRecords": "No s'ha trobat cap entrada",
//            "info": "Mostrant pàgina _PAGE_ de _PAGES_",
            "info": "Mostrant de _START_ a _END_ entrades de _TOTAL_",
            "infoEmpty": "Cap entrada disponible",
            "infoFiltered": "(de _MAX_ entrades totals)",
            "search": "Cerca:",
            "paginate": {
                "first": "Primera",
                "last": "Última",
                "next": "Següent",
                "previous": "Anterior"
            }
        },
        initComplete: function () {
            this.api().columns().every( function (i) {
                var column = this;
                var titolLabel = '';
                if ($(column.header()).data('select-search') == true) {
                	var titolLabel = $(column.header()).data('select-search-label');
                	if(typeof titolLabel == "undefined") {
                		titolLabel = $(column.header()).text();
                	}
                	var info = $(column.header()).data('select-search-items');
                	if(typeof info == "undefined") {
	                    info = column.data().map(function(obj){
	//                        if ($(obj).text()) {
	//                            return $(obj).text();
	//                        } else {
	                            return obj;
	//                        }
	                    });
	                    var collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
	                    info = info.unique().sort(collator.compare);
                	}

                    if (info.length > 1) {
                        var search = $(column.header()).closest('.dataTables_wrapper').find('.dataTables_filter');
//                        search.append('<label>' + $(titol).html() + ':</label>');
                        var select = $('<select class="form-control input-sm"><option value="">' + titolLabel + '</option></select>')
                            .appendTo( search );

                        select.on( 'change', function () {
                                var val = $.fn.dataTable.util.escapeRegex(
                                    $(this).val()
                                );
                                column
                                    .search( val ? '^'+val+'$' : '', true, false )
                                    .draw();
                            } );
                        $.each(info, function ( key, value ) {
                            //TODO: quant no hi ha html falla al map
                            //TODO: no mantÃ© el select al canviar de pÃ gina
                            select.append( '<option value="'+value+'">'+value+'</option>' )
                        } );
                    }



                }
            } );
        }
    });
});



//
//Pipelining function for DataTables. To be used to the `ajax` option of DataTables
//
$.fn.dataTable.pipeline = function ( opts ) {
 // Configuration options
 var conf = $.extend( {
     pages: 5,     // number of pages to cache
     url: '',      // script url
     data: null,   // function or object with parameters to send to the server
                   // matching how `ajax.data` works in DataTables
     method: 'GET' // Ajax HTTP method
 }, opts );

 // Private variables for storing the cache
 var cacheLower = -1;
 var cacheUpper = null;
 var cacheLastRequest = null;
 var cacheLastJson = null;

 return function ( request, drawCallback, settings ) {
     var ajax          = false;
     var requestStart  = request.start;
     var drawStart     = request.start;
     var requestLength = request.length;
     var requestEnd    = requestStart + requestLength;

     if ( settings.clearCache ) {
         // API requested that the cache be cleared
         ajax = true;
         settings.clearCache = false;
     }
     else if ( cacheLower < 0 || requestStart < cacheLower || requestEnd > cacheUpper ) {
         // outside cached data - need to make a request
         ajax = true;
     }
     else if ( JSON.stringify( request.order )   !== JSON.stringify( cacheLastRequest.order ) ||
               JSON.stringify( request.columns ) !== JSON.stringify( cacheLastRequest.columns ) ||
               JSON.stringify( request.search )  !== JSON.stringify( cacheLastRequest.search )
     ) {
         // properties changed (ordering, columns, searching)
         ajax = true;
     }

     // Store the request for checking next time around
     cacheLastRequest = $.extend( true, {}, request );

     if ( ajax ) {
         // Need data from the server
         if ( requestStart < cacheLower ) {
             requestStart = requestStart - (requestLength*(conf.pages-1));

             if ( requestStart < 0 ) {
                 requestStart = 0;
             }
         }

         cacheLower = requestStart;
         cacheUpper = requestStart + (requestLength * conf.pages);

         request.start = requestStart;
         request.length = requestLength*conf.pages;

         // Provide the same `data` options as DataTables.
         if ( $.isFunction ( conf.data ) ) {
             // As a function it is executed with the data object as an arg
             // for manipulation. If an object is returned, it is used as the
             // data object to submit
             var d = conf.data( request );
             if ( d ) {
                 $.extend( request, d );
             }
         }
         else if ( $.isPlainObject( conf.data ) ) {
             // As an object, the data given extends the default
             $.extend( request, conf.data );
         }

         settings.jqXHR = $.ajax( {
             "type":     conf.method,
             "url":      conf.url,
             "data":     request,
             "dataType": "json",
             "cache":    false,
             "success":  function ( json ) {
                 cacheLastJson = $.extend(true, {}, json);

                 if ( cacheLower != drawStart ) {
                     json.data.splice( 0, drawStart-cacheLower );
                 }
                 if ( requestLength >= -1 ) {
                     json.data.splice( requestLength, json.data.length );
                 }

                 drawCallback( json );
             }
         } );
     }
     else {
         json = $.extend( true, {}, cacheLastJson );
         json.draw = request.draw; // Update the echo for each response
         json.data.splice( 0, requestStart-cacheLower );
         json.data.splice( requestLength, json.data.length );

         drawCallback(json);
     }
 }
};

//Register an API method that will empty the pipelined data, forcing an Ajax
//fetch on the next draw (i.e. `table.clearPipeline().draw()`)
$.fn.dataTable.Api.register( 'clearPipeline()', function () {
 return this.iterator( 'table', function ( settings ) {
     settings.clearCache = true;
 } );
} );


$('table.dataTableAjax').each(function( index ) {
    var paginate = true;
    var scroll = false;

    if ($(this).data('scroll')) {
        scroll = true;
    }

    $(this).DataTable({
        bPaginate: paginate,
        bInfo: paginate,
        bSortClasses: false,
        scrollX: scroll,
        autoWidth: false,
        serverSide: true,
        processing: true,
        searching: true,
        paging: true,
        "columnDefs": [ {
            "targets": "actions",
            "data": "download_link",
            "render": function ( data, type, row, meta ) {
              return '<a href="'+data+'">Download</a>';
            }
          } ],
        "ajax": $.fn.dataTable.pipeline( {
            pages: 20, // number of pages to cache
            data : function(d) {
                customFilter = $(document).find('select.customDataTableFilter');
                search = {keyword : d.search.value};
                $.each(customFilter, function ( key, filter ) {
                    search[$(filter).data('field')] = $(filter).val();
                } );
                d.search.value = search;
            }
        } ),
//         ajax : {
//         	data : function(d) {
//                 customFilter = $(document).find('select.customDataTableFilter');
//                 search = {keyword : d.search.value};
//                 $.each(customFilter, function ( key, filter ) {
//                 	search[$(filter).data('field')] = $(filter).val();
//                 } );
//                 d.search.value = search;
//                 return d;
//             }
//         },
        dom:
            "<'row'<'col-sm-8'i><'col-sm-12 search-filter'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-5'l><'col-sm-7'p>>",
        stateSave: true,
        stateDuration: 60 * 60,
        language: {
            "lengthMenu": "Mostrant _MENU_ entrades per pàgina",
            "zeroRecords": "No s'ha trobat cap entrada",
//                "info": "Mostrant pàgina _PAGE_ de _PAGES_",
            "info": "Mostrant de _START_ a _END_ entrades de _TOTAL_",
            "infoEmpty": "Cap entrada disponible",
            "infoFiltered": "(de _MAX_ entrades totals)",
            "search": "Cerca:",
            "paginate": {
                "first": "Primera",
                "last": "Última",
                "next": "Següent",
                "previous": "Anterior"
            },
            "processing": "Processant dades..."
        }
        ,
        initComplete: function () {
            this.api().columns().every( function (i) {
                var column = this;
                var titolLabel = '';
                if ($(column.header()).data('select-search') == true) {
                    var titolLabel = $(column.header()).data('select-search-label');
                    if(typeof titolLabel == "undefined") {
                        titolLabel = $(column.header()).text();
                    }
                    var info = $(column.header()).data('select-search-items');
                    if(jQuery.type(info) === 'object') {

                        var search = $(column.header()).closest('.dataTables_wrapper').find('.dataTables_filter');
                        var select = $('<select class="form-control customDataTableFilter input-sm" data-field="'+$(column.header()).data('name')+'"><option value="">' + titolLabel + '</option></select>')
                            .appendTo( search )
                            .on( 'change', function () {
                                var val = $.fn.dataTable.util.escapeRegex(
                                    $(this).val()
                                );

                                column
                                    .search( val ? '^'+val+'$' : '', true, false )
                                    .draw();
                            } );

                        $.each(info, function ( key, value ) {
                            select.append( '<option value="'+key+'">'+value+'</option>' )
                        } );

                        // ordenació alfabètica dels options
                        var selectOptions = $(select).find("option");
                        var selectedOption = $(select).val();
                        selectOptions.sort(function(a, b) {
                        	if (a.value == '') {
                        		return -1;
                        	}
                        	if (b.value == '') {
                        		return 1;
                        	}
                            if (a.text > b.text) {
                                return 1;
                            }
                            else if (a.text < b.text) {
                                return -1;
                            }
                            else {
                                return 0
                            }
                        });
                        $(select).empty().append(selectOptions);
                        $(select).val(selectedOption);
                    }
                }
            } );
        }
    });
});


$('table.dataTableAjaxFuncions').each(function( index ) {
    var paginate = true;
    var scroll = false;

    if ($(this).data('scroll')) {
        scroll = true;
    }

    $(this).DataTable({
        bPaginate: paginate,
        bInfo: paginate,
        bSortClasses: false,
        scrollX: scroll,
        autoWidth: false,
        serverSide: true,
        processing: true,
        searching: true,
        paging: true,
        "columnDefs": [ {
            "targets": "actions",
            "data": "download_link",
            "render": function ( data, type, row, meta ) {
              return '<a href="'+data+'">Download</a>';
            }
          } ],
        "ajax": $.fn.dataTable.pipeline( {
            pages: 20, // number of pages to cache
            data : function(d) {
            	$(".dataTables_wrapper div.sumary_wrapper").remove();

                customFilter = $(document).find('select.customDataTableFilter');
                search = {keyword : d.search.value};
                $.each(customFilter, function ( key, filter ) {
                    search[$(filter).data('field')] = $(filter).val();
                } );
                d.search.value = search;
            }
        } ),
//         ajax : {
//         	data : function(d) {
//                 customFilter = $(document).find('select.customDataTableFilter');
//                 search = {keyword : d.search.value};
//                 $.each(customFilter, function ( key, filter ) {
//                 	search[$(filter).data('field')] = $(filter).val();
//                 } );
//                 d.search.value = search;
//                 return d;
//             }
//         },
        "footerCallback": function ( row, data, start, end, display ) {

        	var sumaryUrl = document.location + '?summary';
        	$(".dataTables_wrapper div.sumary_wrapper").remove();
        	$.ajax({
                url: sumaryUrl,
                type: 'get',
                success: function(data){


                	$(".dataTableAjaxFuncions").before('<div class="sumary_wrapper" style="display:none"><div class="row">'
                			+ '<div class="col-sm-3"><strong>Nº Funcions</strong> <span>' + data.totalFuncions + '</span></div>'
                			+ '<div class="col-sm-3"><strong>Nº Espectadors</strong> <span>' + data.totalEspectadors + '</span></div>'
                			+ '<div class="col-sm-3"><strong>Nº Espectadors i convidats</strong> <span>' + data.totalEspectadors + '</span></div>'
                			+ '<div class="col-sm-3"><strong>Nº Bolos</strong> <span>' + data.totalBolos + '</span></div>'
                			+ '<div class="col-sm-3"><strong>Total Recaptació</strong> <span>' + data.totalRecaptacio + ' €</span></div>'
                			+ '</div></div>');

                	$(".sumary_wrapper").fadeIn();

                },
                error: function(response) {
                }
            });
        },
        dom:
            "<'row'<'col-sm-8'i><'col-sm-12 search-filter'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-5'l><'col-sm-7'p>>",
        stateSave: true,
        stateDuration: 60 * 60,
        language: {
            "lengthMenu": "Mostrant _MENU_ entrades per pàgina",
            "zeroRecords": "No s'ha trobat cap entrada",
//                "info": "Mostrant pàgina _PAGE_ de _PAGES_",
            "info": "Mostrant de _START_ a _END_ entrades de _TOTAL_",
            "infoEmpty": "Cap entrada disponible",
            "infoFiltered": "(de _MAX_ entrades totals)",
            "search": "Cerca:",
            "paginate": {
                "first": "Primera",
                "last": "Última",
                "next": "Següent",
                "previous": "Anterior"
            },
            "processing": "Processant dades..."
        }
        ,
        initComplete: function () {
            this.api().columns().every( function (i) {
                var column = this;
                var titolLabel = '';
                if ($(column.header()).data('select-search') == true) {
                    var titolLabel = $(column.header()).data('select-search-label');
                    if(typeof titolLabel == "undefined") {
                        titolLabel = $(column.header()).text();
                    }
                    var info = $(column.header()).data('select-search-items');
                    if(jQuery.type(info) === 'object') {

                        var search = $(column.header()).closest('.dataTables_wrapper').find('.dataTables_filter');
                        var select = $('<select class="form-control customDataTableFilter input-sm" data-field="'+$(column.header()).data('name')+'"><option value="">' + titolLabel + '</option></select>')
                            .appendTo( search )
                            .on( 'change', function () {
                                var val = $.fn.dataTable.util.escapeRegex(
                                    $(this).val()
                                );

                                column
                                    .search( val ? '^'+val+'$' : '', true, false )
                                    .draw();
                            } );

                        $.each(info, function ( key, value ) {
                            select.append( '<option value="'+key+'">'+value+'</option>' )
                        } );

                        // ordenació alfabètica dels options
                        var selectOptions = $(select).find("option");
                        var selectedOption = $(select).val();
                        selectOptions.sort(function(a, b) {
                        	if (a.value == '') {
                        		return -1;
                        	}
                        	if (b.value == '') {
                        		return 1;
                        	}
                            if (a.text > b.text) {
                                return 1;
                            }
                            else if (a.text < b.text) {
                                return -1;
                            }
                            else {
                                return 0
                            }
                        });
                        $(select).empty().append(selectOptions);
                        $(select).val(selectedOption);
                    }
                }
            } );
        }
    });
});


$('table.dataTableOnlySort').each(function( index ) {
    var paginate = false;
    var scroll = false;

    $(this).DataTable({
        bPaginate: paginate,
        bInfo: paginate,
        bSortClasses: false,
        autoWidth: false,
        searching: false,
        stateSave: true,
        stateDuration: 60,
        dom:
            "<'row'<'col-sm-8'i><'col-sm-4'f>>" +
            "<'row'<'col-sm-12'tr>>" +
            "<'row'<'col-sm-5'l><'col-sm-7'p>>",
//        stateSave: true,
//        stateDuration: 60 * 60,
        language: {
//            "lengthMenu": "Mostrant _MENU_ entrades per pàgina",
//            "zeroRecords": "No s'ha trobat cap entrada",
//            "info": "Mostrant pàgina _PAGE_ de _PAGES_",
//            "info": "Mostrant de _START_ a _END_ entrades de _TOTAL_",
//            "infoEmpty": "Cap entrada disponible",
//            "infoFiltered": "(de _MAX_ entrades totals)",
//            "search": "Cerca:",
//            "paginate": {
//                "first": "Primera",
//                "last": "Última",
//                "next": "Següent",
//                "previous": "Anterior"
//            }
        },
        initComplete: function () {
//            this.api().columns().every( function (i) {
//            } );
        },
//        order: function (){
//        	console.log('ordering!!');
//        }
    });
});

$('table.dataTableOnlySort').on( 'order.dt', function () {
    // This will show: "Ordering on column 1 (asc)", for example
	var table = $(this).DataTable();
    var order = table.order();
//    console.log( 'Ordering on column '+order[0][0]+' ('+order[0][1]+')' );
//    console.log(table.columns());
//    console.log(table.column(order[0][0]));
//    console.log(table.column(order[0][0]).header());

    console.log($(table.column(order[0][0]).header()).data('orderby'));

    if ($('input[name="order_by"]').length) {
    	var orderbyParts = $(table.column(order[0][0]).header()).data('orderby').split(",");

    	var i;
    	var orderby = Array();
    	for (i = 0; i<orderbyParts.length; i++) {
    		orderby.push(orderbyParts[i] + " " + order[0][1]);
    	}

    	$('input[name="order_by"]').val(orderby.join (', '));
    }

} );



/*
 * Formulari d'edició de Bolo
 */
if ($('form.bolos').length) {
	$('#entitats_id').on('change', function(){
		$.ajax({
            url: '/media/backoffice/ajax/getAforamentByBolo.php',
            data: {id_entitat: $(this).val()},
            type: 'get',
            dataType: 'text',
            success: function(data){
            	$('#aforament').val(data);
            },
            error: function(response) {
            }
        });
	});
}




$("#createListModal").click(function(event) {
	var form = $(this).closest('form');

    event.preventDefault();
    var url=$(this).attr('href');
    vex.dialog.open({
        message: 'Introdueix el nom de la llista que vols crear',
        input: '<input name="listName" type="text" placeholder="Nom de la llista" required />',
        buttons: [
            $.extend({}, vex.dialog.buttons.YES, {
                text: 'Crea'
            }), $.extend({}, vex.dialog.buttons.NO, {
                text: 'Cancel·la'
            })
        ],
        callback: function(value) {
        	if (!value) {

        	} else {
        		if (value.listName != '') {
            		form.append('<input type="hidden" name="listName" value="' + value.listName + '">');
            		form.submit();
        		}
        	}

        }
    });

    return false;
});
